const xa = require("../translations/xa.json");
const enUS = require("../translations/en-US.json");
const frFR = require("../translations/fr-FR.json");
const ptBR = require("../translations/pt-BR.json");
const deDE = require("../translations/de-DE.json");
const esES = require("../translations/es-ES.json");
const zhCN = require("../translations/zh-CN.json");

export const translations = {
  xa,
  "en-US": enUS,
  "fr-FR": frFR,
  "pt-BR": ptBR,
  "de-DE": deDE,
  "es-ES": esES,
  "zh-CN": zhCN,
};
