import React from "react";
import { FormattedMessage } from "react-intl.macro";

const StoreLink = ({ url }) => {
  return (
    <p className="mb-2">
      {url && (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="store-link"
        >
          <FormattedMessage id="store-link" defaultMessage="Visit store" />
        </a>
      )}
    </p>
  );
};

export default StoreLink;
