import React, {useEffect} from "react";
import { useIntl } from 'react-intl';
import { FormattedMessage } from "react-intl.macro";

const Header = ({ email, accountName }) => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'header', defaultMessage: `Notification Preferences`});

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      <h1 className="mt-2 mb-1">
        {title}
      </h1>
      <h2>{email}</h2>
      <p className="large my-2">
        <FormattedMessage
          id="main-msg"
          defaultMessage="Uncheck the types of emails you no longer want to receive about your membership in the {accountName} rewards program."
          values={{ accountName: accountName }}
        />
      </p>
    </>
  );
};

export default Header;
