import React from "react";
import { CSSTransition } from "react-transition-group";
import "../assets/scss/components/StatusMessage.scss";

const StatusMessage = ({ statusMessage, hideAfterTimeout, timeout = 5000 }) => (
  <CSSTransition
    in={statusMessage !== null}
    timeout={timeout}
    classNames="status-message"
  >
    <div className={`status-message mb-1_25 ${hideAfterTimeout || 'no-hide'}`}>
      {statusMessage}
    </div>
  </CSSTransition>
);

export default StatusMessage;
