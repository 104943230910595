import React from "react";
import { FormattedMessage } from "react-intl.macro";
import Checkbox from './Checkbox';

const NotificationPreferences = ({
  notificationGroups,
  account,
  onNotificationSuppressionToggled,
}) => {
  const messages = {
    confirmation: {
      name: (
        <FormattedMessage
          id="email-group.confirmation.name"
          defaultMessage="Confirmation emails"
        />
      ),
      description: (
        <FormattedMessage
          id="email-group.confirmation.description"
          defaultMessage="Sent when you complete an action in the rewards program and receive a reward."
        />
      )
    },
    reminder: {
      name: (
        <FormattedMessage
          id="email-group.reminder.name"
          defaultMessage="Reminder emails"
        />
      ),
      description: (
        <FormattedMessage
          id="email-group.reminder.description"
          defaultMessage="Sent for your birthday reward (if applicable), or when a reward is close to expiring."
        />
      )
    },
    promotion: {
      name: (
        <FormattedMessage
          id="email-group.promotion.name"
          defaultMessage="promotion emails"
        />
      ),
      description: (
        <FormattedMessage
          id="email-group.promotion.description"
          defaultMessage="Sent to inform you of special promotions and ways to earn rewards. Changing this setting will impact your preferences for other messages or mailing lists from {accountName}."
          values={{ accountName: account.name }}
        />
      )
    }
  };

  return (
    <div>
      {notificationGroups.map((g) => {
        return (
          <Checkbox
            className="mb-2"
            key={`group-${g.id}`}
            id={g.id}
            isChecked={g.isChecked}
            text={messages[g.slug].name}
            subtext={messages[g.slug].description}
            onClick={onNotificationSuppressionToggled}
          />
        );
      })}
    </div>
  );
};

export default NotificationPreferences;
