import cssVars from "css-vars-ponyfill";
import React, { Component } from "react";
import { IntlProvider } from "react-intl";
import { FormattedMessage } from "react-intl.macro";
import { Form, Spinner } from "reactstrap";
import request from "superagent";
import logo from "./assets/img/checkmark.svg";
import "./assets/scss/App.scss";
import Header from "./components/Header";
import Logo from "./components/Logo";
import NotificationPreferences from "./components/NotificationPreferences";
import SaveButton from "./components/SaveButton";
import SmileBrandingFooter from "./components/SmileBrandingFooter";
import StatusMessage from "./components/StatusMessage";
import StoreLink from "./components/StoreLink";
import { translations } from "./utils/translations";

export default class App extends Component {
  constructor() {
    super();

    const urlParams = new URLSearchParams(window.location.search);

    this.state = {
      pageLoading: true,
      pageErrorMessage: null,
      formLoading: false,
      statusMessage: null,
      hideStatusMessageAfterTimeout: true,
      customerAuthToken: urlParams.get("token") || null,
      customerPreferences: null,
      toggledGroupSuppressions: {},
    };

    const autoUnsubscriptionGroupIds = urlParams.get('autoUnsubscriptionGroupIds');
    this.autoUnsubscriptionGroupIds = autoUnsubscriptionGroupIds
      ? autoUnsubscriptionGroupIds.split(',').reduce((validGroupIds, groupId) => {
        const parsedGroupId = parseInt(groupId);
        if (!isNaN(parsedGroupId)) {
          validGroupIds.push(parsedGroupId);
        }
        return validGroupIds;
      }, [])
      : null;
  }

  autoUnsubscriptionGroupIds = null;

  componentDidMount() {
    this.init();
  }

  async init() {
    const notificationPreferences = await this.fetchNotificationPreferences();
    this.setState({
      customerPreferences: notificationPreferences,
      pageErrorMessage: notificationPreferences ? null : 'Please use a link in a rewards email you have received to update your notification preferences.',
      pageLoading: false,
    });

    if (!notificationPreferences) {
      return;
    }

    if (this.autoUnsubscriptionGroupIds && this.autoUnsubscriptionGroupIds.length > 0) {
      this.performAutoUnsubscriptions();
    }
  }

  async fetchNotificationPreferences() {
    let token = this.state.customerAuthToken;
    if (!token) {
      return null;
    }

    try {
      const res = await request
        .get(
          `${process.env.REACT_APP_PLATFORM_URL}/v1/customer_notification_preferences`
        )
        .set({
          Accept: "application/json",
          "Authorization": `Bearer ${token}`,
        });

      return res.body.customer_notification_preference;
    } catch {
      return null;
    }
  }

  async performAutoUnsubscriptions() {
    // Find valid groups to unsubscribe from that the customer hasn't already unsubscribed from.
    const { autoUnsubscriptionGroupIds, state: { customerPreferences } } = this;
    const {
      customer_notification_groups: notificationGroups,
      customer_notification_suppressions: existingSuppressions,
    } = customerPreferences;
    const toggledGroupSuppressions = { ...this.state.toggledGroupSuppressions };

    autoUnsubscriptionGroupIds.forEach((groupId) => {
      // Skip the group if it doesn't exist or the customer already has a suppression for it.
      if (
        !notificationGroups.find((group) => group.id === groupId)
        || existingSuppressions.find((suppression) => suppression.customer_notification_group_id === groupId)
      ) {
        return;
      }

      toggledGroupSuppressions[groupId] = true;
    });

    this.setState({
      toggledGroupSuppressions,
      hideStatusMessageAfterTimeout: false,
    });

    try {
      await this.updateSubscriptions();

      // Remove the autoSubscriptionGroupIds query parameter from the URL so that the shopper
      // doesn't see unexpected behaviour if they update preferences then reload the page.
      const url = new URL(window.location);
      url.searchParams.delete('autoUnsubscriptionGroupIds');
      window.history.replaceState(null, '', url.toString());
    } catch {}
  }

  handleNotificationSuppressionToggled(e) {
    const { name: groupId } = e.target;
    const { toggledGroupSuppressions } = this.state;

    if (toggledGroupSuppressions[groupId]) {
      delete toggledGroupSuppressions[groupId];
    } else {
      toggledGroupSuppressions[groupId] = true;
    }

    this.setState({
      toggledGroupSuppressions,
      statusMessage: null,
    });
  }

  async updateSubscriptions() {
    const {
      customerPreferences,
      toggledGroupSuppressions,
      customerAuthToken,
    } = this.state;
    const toggledGroupIds = Object.keys(toggledGroupSuppressions);
    
    if (!toggledGroupIds.length) {
      return;
    }
    
    // Merge toggled suppressions into customer preferences so we can send them to the back end.
    const updatedSuppressions = this.calculateUpdatedSuppressions({
      existingSuppressions: customerPreferences.customer_notification_suppressions,
      toggledGroupIds,
    });
    this.setState({
      formLoading: true,
      statusMessage: null,
    });

    try {
      const res = await request
        .post(
          `${process.env.REACT_APP_PLATFORM_URL}/v1/customer_notification_preferences/update_subscriptions`
        )
        .set({
          Accept: "application/json",
          Authorization: `Bearer ${customerAuthToken}`,
        })
        .send({
          ...customerPreferences,
          customer_notification_suppressions: updatedSuppressions,
        });

      this.setState({
        customerPreferences: {
          ...customerPreferences,
          customer_notification_suppressions: [
            ...res.body.customer_notification_suppressions,
          ],
        },
        toggledGroupSuppressions: {},
      });

      this.showSuccessMessage();
    } catch {
      this.showErrorMessage();
    } finally {
      this.setState({
        formLoading: false,
      })
    }
  }

  calculateUpdatedSuppressions({
    existingSuppressions,
    toggledGroupIds,
  }) {
    let updatedSuppressions = [...existingSuppressions];
    toggledGroupIds.forEach((groupId) => {
      groupId = parseInt(groupId);
      let hadExistingSuppression = false;

      // Remove the existing suppression if it exists.
      for (let i = 0; i < updatedSuppressions.length; i++) {
        const existingSuppression = updatedSuppressions[i];
        if (existingSuppression.customer_notification_group_id !== groupId) {
          continue;
        }
  
        hadExistingSuppression = true;
        updatedSuppressions.splice(i, 1);
        break;
      }

      if (!hadExistingSuppression) {
        // Create a new suppression.
        updatedSuppressions.push({
          customer_notification_group_id: groupId,
        });
      }
    });

    return updatedSuppressions;
  }

  showSuccessMessage() {
    const successMessage = (
      <div>
        <img src={logo} className="mr-0_5" alt="Checkmark" loading="lazy" />
        <span className="large">
          <FormattedMessage
            id="save-success-msg"
            defaultMessage="Your preferences have been updated."
          />
        </span>
      </div>
    );

    this.setState({
      statusMessage: successMessage,
    });
  }

  showErrorMessage() {
    const failureMessage = (
      <span className="large">
        <p>
          <FormattedMessage
            id="save-failure-msg-1"
            defaultMessage="Could not update your preferences at this time."
          />
        </p>
        <p>
          <FormattedMessage
            id="save-failure-msg-2"
            defaultMessage="Please try again later."
          />        
        </p>
      </span>
    );

    this.setState({
      statusMessage: failureMessage,
    });
  }

  render() {
    let {
      pageLoading,
      pageErrorMessage,
      formLoading,
      statusMessage,
      hideStatusMessageAfterTimeout,
      customerPreferences,
      toggledGroupSuppressions,
    } = this.state;

    if (pageLoading || pageErrorMessage) {
      return (
        <div className="container page-center">
          {pageLoading && <Spinner color="secondary" />}
          {pageErrorMessage}
        </div>
      );
    }

    let {
      account,
      customer,
      customer_notification_groups,
      customer_notification_theme,
      customer_notification_suppressions,
      display_setting
    } = customerPreferences;

    let accountName = account.name;

    // CSS vars polyfill
    cssVars({
      variables: {
        "--button-color": display_setting.button_color,
        "--button-font-color": display_setting.button_font_color,
        "--border-radius": display_setting.border_radius
      }
    });

    const locale = display_setting.customer_locale || "en-US";
    const notificationGroupDetails = customer_notification_groups.map(({
      id,
      slug,
    }) => {
      const isChecked = !customer_notification_suppressions.find(
        (suppression) => suppression.customer_notification_group_id === id
      );

      return {
        id,
        slug,
        isChecked: toggledGroupSuppressions[id] ? !isChecked : isChecked,
      };
    });

    return (
      <IntlProvider
        locale={locale}
        defaultLocale="en-US"
        messages={translations[locale]}
        onError={null}
      >
        <main className="container" role="main">
          <div className="col-md-8 offset-md-2">
            <Logo logoUrl={customer_notification_theme.logo_url} />
            <Header email={customer.email} accountName={accountName} />

            <Form>
              <NotificationPreferences
                notificationGroups={notificationGroupDetails}
                account={account}
                onNotificationSuppressionToggled={this.handleNotificationSuppressionToggled.bind(this)}
              />
              <div className="form-footer text-center">
                <StatusMessage
                  statusMessage={statusMessage}
                  hideAfterTimeout={hideStatusMessageAfterTimeout}
                />
                <SaveButton
                  loading={formLoading}
                  disabled={formLoading || !Object.keys(toggledGroupSuppressions).length}
                  onClick={() => {
                    this.setState({ hideStatusMessageAfterTimeout: true });
                    this.updateSubscriptions();
                  }}
                />
                <StoreLink url={account.store_url} />
              </div>
            </Form>
            <SmileBrandingFooter
              showSmileBranding={display_setting.show_smile_branding}
            />
          </div>
        </main>
      </IntlProvider>
    );
  }
}
